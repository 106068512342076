import React, {useRef, useState} from 'react';
import { BiPencil, BiTrash } from 'react-icons/bi';
import { AiOutlineQrcode } from 'react-icons/ai';

import { Localizacao, Loja } from '../../../types';
import lojaApi from '../../../services/lojaApi';
import useTable from '../../../hooks/useTable';

import PageContainer from '../../../components/container/PageContainer';
import SearchPageCard from '../../../components/card/SearchPageCard';
import Table from '../../../components/table/Table';
import PageCard from '../../../components/card/PageCard';
import Title from '../../../components/text/Title';
import Input from '../../../components/input/Input';
import localizacaoApi from '../../../services/localizacaoApi';
import IconButton from '../../../components/button/IconButton';
import Alert from '../../../helpers/Alert';
import Modal from '../../../components/modal/Modal';
import useModal from '../../../hooks/useModal';
import Form from '../../../components/form/Form';
import Button from '../../../components/button/Button';
import Select from '../../../components/select/Select';
import CreationInput from '../../../components/input/CreationInput';
import useAppContext from '../../../hooks/useAppContext';
import ReactToPrint from "react-to-print";

class ComponentToPrint extends React.Component {
  render() {
    return (<h1>teste</h1>);
  }
}
export default function Locations() {
  const { cliente } = useAppContext();
  const [loading, setLoading] = useState(false);
  const [text, setText] = useState('');
  const [localizacao, setLocalizacao] = useState<Localizacao>({} as Localizacao);
  const [loja, setLoja] = useState({} as Loja);
  const [idLoja, setIdLoja]: any = useState();

  const { modalProps, open, close } = useModal();

  const [modalData, setModalData] = useState({
    modawShow: 'form',
    title: 'Editar Localização',
  });

  const [qrCode, setQrCode] = useState('');

  const { tableProps: localizacaoTableProps, loadData } = useTable<Localizacao>({
    denyInitialLoad: true,
    dataLoader: async (params) => {
      if (params) {
        params['loja_id'] = idLoja;
      }
      const { data, isError } = await localizacaoApi.all(params);
      return isError ? false : data;
    }
  });

  const { tableProps, data, onSearch } = useTable<Loja>({
    dataLoader: async (params) => {
      const { data, isError } = await lojaApi.all(params);
      return isError ? false : data;
    },
    onItemClick: (item) => {
      setLoja(item);
      setIdLoja(item.id);
      let data = {'loja_id': item.id};
      loadData(data);
    },
  });

  async function handleDelete(localizacao: Localizacao) {
    const confirm = await Alert.confirm('Tem certeza que deseja excluir?', 'Excluir localização?');
    if (! confirm) return;
    Alert.await('Excluindo localização...');
    const { data, isError } = await localizacaoApi.destroy(localizacao.id);
    Alert.close();
    if (isError) return Alert.error(data.message);
    loadData();
  }

  async function handleSubmit() {
    setLoading(true);
    const { data, isError } = await localizacaoApi.update(localizacao.id, localizacao);
    setLoading(false);
    if (isError) return Alert.error(data.message);
    loadData();
    close();
  }

  async function createLocalizacao() {
    if (!loja.id) {
      Alert.error('Selecione uma loja..');
      return;
    }

    Alert.await('Registrando localização');
    const { data, isError } = await localizacaoApi.create({
      descricao: text,
      cliente_id: String(cliente?.id),
      loja_id: String(loja.id),
    });
    Alert.close();
    if (isError) return Alert.error(data.message);
    loadData();
    setText('');
  }

  async function qrcode(dataArray: any){
    Alert.await('Gerando QR code');
    let urlBack = process.env.REACT_APP_API;
    urlBack = urlBack?.split('api')[0];

    const { data, isError } = await localizacaoApi.qrCode(dataArray);

    Alert.close();

    if (isError) return alert(data.message);

    setQrCode(urlBack+data);
  }
  let componentRef = useRef(null);
  return (  
    <PageContainer menuTitle="Localizações">
      <div className="grid grid-cols-3 gap-2">
        <div className="flex flex-col gap-2">
          <SearchPageCard
            fullWidthInput
            onSearch={onSearch}
            placeholder="Cadastro de Localizações"
          />
          <Table<Loja> {...tableProps} columns={[
            { title: 'Loja', field: 'razao_social' },
            { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus', width: 100 },
          ]}/>
        </div>

        <PageCard
          removeContentPadding 
          className="col-span-2"
          header={<Title color="primary">Cadastro de Localizações</Title>}
        >
          <header className="grid grid-cols-3 gap-5 border-b p-5 pb-7">
            <Input disabled label="Loja" value={loja.razao_social} className="col-span-2" />
            <Input disabled label="Status" value={loja.bloqueado ? 'Inativo' : 'Ativo'} />
          </header>

          <main className="p-5">
            <span>Localizações vinculadas</span>
            <CreationInput 
              placeholder="Adicionar Localização"
              value={text}
              setValue={setText}
              onBtnClick={createLocalizacao}
            />
            <Table
              {...localizacaoTableProps}
              className="mt-5"
              columns={[
                { title: 'Localizações Vinculadas', field: 'descricao' },
                { title: 'Status', field: 'bloqueado', columnType: 'reversedStatus', width: 100 },
                { title: '', width: 150, render: (l) => (
                  <div>
                    <IconButton icon={BiPencil} onClick={() => {
                      setModalData({
                        modawShow: 'form',
                        title: 'Editar Localização',
                      });
                      setLocalizacao(l);
                      open();
                    }} />
                    <IconButton icon={AiOutlineQrcode} onClick={() => {
                      setQrCode('');
                      setModalData({
                        modawShow: 'qrCode',
                        title: 'QR Code',
                      });
                      qrcode(l);
                      open();
                    }} />
                    <IconButton icon={BiTrash} onClick={() => handleDelete(l)} />
                  </div>
                ) },
              ]}
            />
          </main>
        </PageCard>

        <Modal size="md" title={modalData.title} {...modalProps}>
          {
            modalData.modawShow === 'form' ?
              <Form onSubmit={handleSubmit}>
                <Input
                  label="Descrição"
                  value={localizacao.descricao}
                  setValue={(descricao) => setLocalizacao({ ...localizacao, descricao })}
                />
                <div className="mt-3"></div>
                <Select
                  label="Status"
                  value={localizacao.bloqueado}
                  setValue={(bloqueado) =>setLocalizacao({ ...localizacao, bloqueado })}
                  items={[
                    { label: 'Ativo', key: false },
                    { label: 'Inativo', key: true },
                  ]}
                />
                <Button
                  type="submit"
                  className="w-full mt-6"
                  disabled={!localizacao.descricao?.length}
                  loading={loading}
                >Salvar</Button>
              </Form>
              :
              <>
                <div className={'flex justify-center'} ref={componentRef}>
                  <img src={qrCode} />
                </div>
                <ReactToPrint
                  trigger={() => <div className={'flex justify-center mt-3'} ><Button>Imprimir</Button></div>}
                  content={() => componentRef.current}
                />
              </>
          }
        </Modal>

      </div>
    </PageContainer>
  );
}
