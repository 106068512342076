import React, {useEffect, useState} from 'react';
import Alert from '../../../helpers/Alert';
import localizacaoApi from '../../../services/localizacaoApi';
import dashboardApi from '../../../services/dashboardApi';
import PageContainer from '../../../components/container/PageContainer';
import Title from '../../../components/text/Title';
import PageCard from '../../../components/card/PageCard';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';

import Modal from '../../../components/modal/Modal';
import useModal from '../../../hooks/useModal';

import Str from '../../../helpers/Str';
import lojaApi from '../../../services/lojaApi';
import api from '../../../services/api';
import { saveAs } from 'file-saver';
import { IoIosSave } from "react-icons/io";
import { MdImageSearch } from "react-icons/md";
export default function ValidityBrigade() {
  const [selectStore, setSelectStore] = useState([]);
  const [searchDataSelect, setSearchDataSelect] = useState<any>('');

  const [searchData, setSearchData] = useState<string>(new Date().toISOString().split("T")[0]);
  const [data, setData] = useState<any>([]);
  const [images, setImages] = useState<any>([]);
  const [selectLocalizacao, setSelectLocalizacao] = useState<any>(['Todos']);
  const [searchDataLocalizacao, setSearchDataLocalizacao] = useState<any>(['Todos']);
  const [searchLoad, setSearchLoad] = useState(false);
  const [imgGrande, setImgGrande] = useState(false);
  const [urlImg, setUrlImg] = useState('');

  const { modalProps, open, close } = useModal();

  async function loadData() {
    Alert.await(`Carregando dados...`);
    const {data, isError} = await lojaApi.listAll();
    if (isError) return alert(data.message || 'Falha ao carregar dados da loja.');
    let dtSelect = data.map((res: any) => ({label:res.razao_social, key: res.id}));
    dtSelect.unshift({label:'Todos', key: ''});
    setSelectStore(dtSelect as any);

    const dataLocalization = await localizacaoApi.listAll();
    if (dataLocalization.isError) return alert(dataLocalization.data.message || 'Falha ao carregar dadas localizações.');
    let dtlocSelect = dataLocalization.data.map((res: any) => ({label:res.descricao, key: res.descricao}));
    dtlocSelect.unshift({label:'Todos', key: 'Todos'});
    setSelectLocalizacao(dtlocSelect as any);
    Alert.close();
  }

  useEffect(() => {
    loadData();
  }, []);

  const handleClick = async () => {
    if (!searchData) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a data');
    }
    /*if (!searchDataLocalizacao) {
      setSearchLoad(false);
      return Alert.error('É necessário selecionar a localização');
    }*/

    let dataLocalizacao = searchDataLocalizacao ? [...searchDataLocalizacao] : [];

    var indice = dataLocalizacao.indexOf("Todos");
    if (!(indice < 0)){
      dataLocalizacao.splice(indice, 1);
    }
    setSearchLoad(true);
    const {data, isError} = await dashboardApi.dashboardBreigadaVencimento(
      searchData,
      dataLocalizacao,
      searchDataSelect
    );
    setData(data);
    setSearchLoad(false);
  }

  const diasVencerData = (data: any) =>{
    //console.log(new Date().toISOString().split("T")[0]);
    const day = new Date().toISOString().split("T")[0];
    const qtdDias = Str.countDate(day, data);
    return(qtdDias);
  }

  const backgoundCollorDiasVencimento = (num) =>{
    //bg-green-500  bg-yellow-500  bg-red-500 bg-violet-500
    if (num >= 30) {
      return 'bg-green-500';
    }
    if (num <= 2) {
      return 'bg-violet-500';
    }
    if (num <= 15) {

      return 'bg-red-500';
    }
    if (num < 30) {
      return 'bg-yellow-500';
    }
  }

  const excel = async () => {
    let dataLocalizacao = searchDataLocalizacao ? [...searchDataLocalizacao] : [];
    var indice = dataLocalizacao.indexOf("Todos");
    dataLocalizacao.splice(indice, 1)
    // @ts-ignore
    let url = `dashboard/excel/brigadaVencimento?data_consulta=${searchData}&local=${dataLocalizacao && dataLocalizacao !== 'Todos' ? dataLocalizacao : ''}&id_loja=${searchDataSelect && searchDataSelect !== 'todos' ? searchDataSelect : ''}`;

    api.get(url, { responseType: 'blob' }).then(({ data }) => {
      const filename = 'BragadaDeValidade.xlsx';
      const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
      saveAs(blob, filename);
    })
      .catch(error => {
        console.log('aki')
      });
  }

  const saveComentario = async (params: any) => {

    const paramsData = {
      "leitura_id": params.leitura_id,
      "produto_id": params.produto_id,
      "validade": params.validade,
      "observacao": params.observacao
    }
    const {data, isError} = await dashboardApi.saveObsBrigadaValidade(paramsData);
    if (!isError){
      Alert.success('Observação salva com sucesso!');
    }
  }

  const changeObs = (index, value) => {
    const newData  = [...data];
    newData[index]['observacao'] = value;
    setData(newData);
  }

  const openModalImage = (imagesData: any) => {
    setImages(imagesData.map(img => img.path))
    open()
  }

  const viewImg = (url) => {
    setUrlImg(url)
    setImgGrande(true);
  }
  const backImage = () => {
    setUrlImg('')
    setImgGrande(false);
  }





  let cnt = 0;
  return(
    <PageContainer menuTitle="Brigada de validade" pageTitle="Brigada de validade">
      <PageCard
        header={
          <div className="flex items-center justify-between">
            <Title color="primary">Brigada de validade</Title>
          </div>
        }
      >
        <div className="grid grid-cols-12 gap-5 pb-7">
          <Select
            label="Seleciona loja"
            className="col-span-3"
            value={searchDataSelect}
            setValue={v => setSearchDataSelect(v)}
            items={selectStore}
          />
          <Select
            multiple={true}
            label="Seleciona localização"
            className="col-span-3"
            value={searchDataLocalizacao}
            setValue={v => setSearchDataLocalizacao(v)}
            items={selectLocalizacao}
          />

          <Input
            label="Data"
            type={"date"}
            className="col-span-2"
            value={searchData}
            setValue={(v) => setSearchData(v)}
          />

          <Button
            className="min-w-[147px] h-[37.5px] mt-[1.5rem]"
            onClick={() => handleClick()}
            loading={searchLoad}
          >
            Buscar dados
          </Button>
          {
            data.length ?
              <Button
                className="min-w-[147px] h-[37.5px] mt-[1.5rem] ml-[5rem]"
                onClick={() => excel()}
                loading={searchLoad}
              >
                Gerar excel
              </Button>
              : ''
          }

        </div>
        <div className={'flex-row'}>
          <div className={'pb-5 w-[100%]'}>
            {
              data.length ?
                <table className="table-auto w-[100%]">
                  <thead className="bg-light border-b">
                  <tr className={'border-t'}>
                    <th className={'text-start py-3 px-5'}>EAN</th>
                    <th className={'text-start py-3 px-5'}>Descrição</th>
                    <th className={'text-start py-3 px-5'}>Loja</th>
                    <th className={'text-start py-3 px-5'}>Local</th>
                    <th className={'text-start py-3 px-5'}>Validade</th>
                    <th className={'text-start py-3 px-5'}>Quantidade</th>
                    <th className={'text-start py-3 px-5'}>Data da leitura</th>
                    <th className={'text-start py-3 px-5'}>Dias a vencer</th>
                    <th className={'text-start py-3 px-5'}>Obs</th>
                    <th className={'text-start py-3 px-5'}>img</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    data.length ? data.map((dt: any, key) => {
                      if (data.length === key+1){
                        cnt += parseInt(dt.quantidade);
                        return(
                          <>
                            <tr>
                              <td className="px-5">{dt.produto.ean}</td>
                              <td className="px-5">{dt.produto.descricao}</td>
                              <td className="px-5">{dt.leitura ? dt.leitura.loja.razao_social : ''}</td>
                              <td className="px-5">{dt.leitura ? dt.leitura.localizacao.descricao : ''}</td>
                              <td className="px-5">{Str.convertDate(dt.validade)}</td>
                              <td className="px-5">{parseInt(dt.quantidade)}</td>
                              <td className={`px-5 `}>{Str.convertDateCreatedAt(dt.created_at)}</td>
                              <td className={`px-5 ${backgoundCollorDiasVencimento(diasVencerData(dt.validade))}`}>{ diasVencerData(dt.validade) }</td>
                              <td className="px-1 flex align-end">
                                <textarea className={'w-full border'} onChange={(e) => changeObs(key, e.target.value)}>
                                  {dt.observacao}
                                </textarea>
                                <button onClick={() => saveComentario(dt)} title={"Salvar comentario"} className={' w-[35px] h-[35px] justify-center items-center'}>
                                  <IoIosSave className={'hover:text-gray-400'} size={20} />
                                </button>
                              </td>
                              <td >
                                <div className={'flex justify-center'}>
                                  <button onClick={() => openModalImage(dt.fotos)} title={"visualizar imagem"} className={' w-[35px] h-[35px]  flex justify-center items-center'}>
                                    <MdImageSearch className={'hover:text-gray-400'} size={20} />
                                  </button>
                                </div>
                              </td>

                            </tr>
                            <tr className={'border-t'}>
                              <td className="px-5"></td>
                              <td className="px-5"></td>
                              <td className="px-5"></td>
                              <td className="px-5"></td>
                              <td className="px-5"></td>
                              <td className="px-5"></td>
                              <td className="px-5 font-bold">Total:</td>
                              <td className="px-5">{cnt}</td>
                            </tr>
                          </>
                        )
                      }else{
                        cnt += parseInt(dt.quantidade);
                        //bg-green-500  bg-yellow-500  bg-red-500 bg-violet-500
                        return(
                          <tr>
                            <td className="px-5">{dt.produto.ean}</td>
                            <td className="px-5">{dt.produto.descricao}</td>
                            <td className="px-5">{dt.leitura ? dt.leitura.loja.razao_social : ''}</td>
                            <td className="px-5">{dt.leitura ? dt.leitura.localizacao.descricao : ''}</td>
                            <td className="px-5">{Str.convertDate(dt.validade)}</td>
                            <td className="px-5">{parseInt(dt.quantidade)}</td>
                            {/*<td className="px-5 bg-green-500">{ diasVencerData(dt.validade) }</td>*/}
                            <td className={`px-5 `}>{Str.convertDateCreatedAt(dt.created_at)}</td>
                            <td className={`px-5 ${backgoundCollorDiasVencimento(diasVencerData(dt.validade))}`}>{ diasVencerData(dt.validade) }</td>
                            <td className="px-1 flex align-end">
                                <textarea className={'w-full border'} onChange={(e) => changeObs(key, e.target.value)}>
                                  {dt.observacao}
                                </textarea>
                              <button onClick={() => saveComentario(dt)} title={"Salvar comentario"} className={' w-[35px] h-[35px]  flex justify-center items-center'}>
                                <IoIosSave className={'hover:text-gray-400'} size={20} />
                              </button>
                            </td>
                            <td >
                              <div className={'flex justify-center'}>
                                <button onClick={() => openModalImage(dt.fotos)} title={"visualizar imagem"} className={' w-[35px] h-[35px]  flex justify-center items-center'}>
                                  <MdImageSearch className={'hover:text-gray-400'} size={20} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      }
                    }) : ''
                  }
                  </tbody>
                </table>
                :
                ''
            }

          </div>

        </div>
      </PageCard>
      <Modal  title={"Imagens"} {...modalProps}>
        {
          imgGrande ?
            <div className={'flex flex-col'}>
              <div className={'mb-3'}>
                <Button onClick={() => backImage()}>Voltar</Button>
              </div>
              <img
                src={urlImg}
                className={'hover:cursor-pointer'}
              />
            </div>

            :

            <div className="grid grid-cols-5 gap-4">
              {
                images.map(img => {
                  return(
                  <img
                    onClick={() => viewImg(img)}
                    src={img}
                    className={'hover:cursor-pointer'}
                  />
                  )

                })
              }
            </div>
        }

        {/*{
            images.map(img => {
              return(
                <div>
                  <img src={img} />
                </div>
              )

            })
        }*/}
      </Modal>
    </PageContainer>
  );
}