import {Localizacao, QueryParams} from '../types';
import api, {create, deleteOne, getAll, getOne, RequestError, RequestResponse, update, listAll} from './api';
const endpoint = 'localizacoes';
export default {
  all: async (params?: QueryParams) => getAll<Localizacao>({ endpoint, params }),
  one: async (id: string | number) => getOne<Localizacao>({ endpoint, id }),
  listAll: async () => listAll<Localizacao>({ endpoint }),
  destroy: async (id: string | number) => deleteOne({ endpoint, id }),
  create: async (formData: Partial<Localizacao>) => create<Localizacao>({ endpoint, formData }),
  update: async (id: string | number, formData: Partial<Localizacao>) => update<Localizacao>({ endpoint, formData, id }),
  async qrCode (formData: Partial<Localizacao>): Promise<RequestError | RequestResponse> {
    try {
      const { data } = await api.post(endpoint+'/qrcode', formData);
      return { data, isError: false };
    } catch (err: any) {
      return {
        data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
        isError: true
      };
    }
  },
};
